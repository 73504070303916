<template>
  <v-form @submit.prevent="saveProject" ref="form" v-model="valid" lazy-validation>
    <v-card class="mb-5">
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field
              :rules="[...$rules.required, ...$rules.maxLength(50)]"
              label="Nazwa"
              outlined
              dense
              v-model="project.name"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              :rules="[...$rules.required, ...$rules.maxLength(50)]"
              label="Domena"
              outlined
              dense
              :hint="`Nazwa domeny bez 'https://www.' Np. 'example.com'`"
              v-model="project.domain"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              :rules="[...$rules.required, ...$rules.maxLength(100)]"
              label="Klucz API Ceneo"
              outlined
              dense
              v-model="project.ceneoAPIKey"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              :rules="[...$rules.required, ...$rules.percentage, ...$rules.intOnly]"
              type="number"
              label="% niepodbitych produktów dla cofnięcia licytacji kategorii"
              outlined
              dense
              v-model="project.procentOfUnbiddedProductsInCategoryToUnbid"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-5">
      <v-card-title>
        <v-switch inset v-model="project.bidding" label="Licytacja" />
      </v-card-title>
    </v-card>
    <v-card class="mb-5">
      <v-card-title>
        <v-switch inset v-model="project.senutoRaport" label="Raport Senuto" />
      </v-card-title>
      <v-card-text v-if="project.senutoRaport">
        <v-row>
          <v-col cols="6">
            <v-text-field
              ref="senutoMin"
              @input="validateField('senutoMax')"
              type="number"
              :rules="[
                ...$rules.required,
                ...$rules.greaterThan(0),
                ...$rules.intOnly,
                ...senutoMinRule,
              ]"
              label="Minimalna ilość wyszukiwań"
              outlined
              dense
              v-model.number="project.senutoMinimumSearch"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              @input="validateField('senutoMin')"
              ref="senutoMax"
              type="number"
              :rules="[
                ...$rules.required,
                ...$rules.greaterThan(0),
                ...$rules.intOnly,
                ...senutoMaxRule,
              ]"
              label="Maksymalna ilość wyszukiwań"
              outlined
              dense
              v-model.number="project.senutoMaximumSearch"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              :rules="[...$rules.required, ...$rules.percentage, ...$rules.intOnly]"
              type="number"
              label="% zgodności słów kluczowych do kategorii"
              outlined
              dense
              v-model="project.senutoComparingKeywordsPercentage"
            />
          </v-col>
          <v-col cols="12">
            <v-data-table
              :search="searchCompetitor"
              item-key="concurrentID"
              :items="project.concurrents"
              :headers="headers"
            >
              <template v-slot:top>
                <v-toolbar class="elevation-0">
                  <AddCompetitorModal @addedCompetitor="addCompetitor" ref="competitorModal" />
                  <v-spacer />
                  <v-text-field
                    v-if="project.concurrents.length > 0"
                    v-model="searchCompetitor"
                    append-icon="mdi-magnify"
                    class="shrink"
                    label="Wyszukaj"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{item}">
                <EditCompetitorModal :competitor="item" @update:competitor="editCompetitor" />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-5">
      <v-card-title>
        <v-switch inset v-model="project.searchNodeRaport" label="Raport Searchnode" />
      </v-card-title>
      <v-card-text v-if="project.searchNodeRaport">
        <v-row>
          <v-col cols="6">
            <v-text-field
              :rules="[...$rules.required, ...$rules.percentage, ...$rules.intOnly]"
              type="number"
              label="% zgodności słów kluczowych do kategorii"
              outlined
              dense
              v-model="project.searchNodeComparingKeywordsPercentage"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              :rules="[...$rules.required, ...$rules.greaterThan(0), ...$rules.intOnly]"
              type="number"
              label="Ilość kategorii do licytacji"
              outlined
              dense
              v-model="project.searchNodeNumberOfCategoriesToBid"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-5">
      <v-card-title>
        <v-switch inset v-model="project.exactagRaport" label="Raport Exactag" />
      </v-card-title>
      <v-card-text v-if="project.exactagRaport">
        <v-row>
          <v-col cols="6">
            <v-text-field
              :rules="[...$rules.required, ...$rules.greaterThan(0), ...$rules.intOnly]"
              type="number"
              label="Ilość produktów do licytacji z aktualnego tygodnia"
              outlined
              dense
              v-model="project.exactagNumberWeeklyBidding"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              :rules="[...$rules.required, ...$rules.greaterThan(0), ...$rules.intOnly]"
              type="number"
              label="Ilość produktów do licytacji z historycznego tygodnia"
              outlined
              dense
              v-model="project.exactagNumberWeeklyBiddingYearAgo"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-5">
      <v-card-title>
        <v-switch inset v-model="project.tradeWatchRaport" label="Raport Tradewatch" />
      </v-card-title>
      <v-card-text v-if="project.tradeWatchRaport">
        <v-row>
          <v-col cols="12">
            <v-text-field
              :rules="[...$rules.required, ...$rules.greaterThan(0), ...$rules.intOnly]"
              type="number"
              label="Ilość kategorii do licytacji"
              outlined
              dense
              v-model="project.tradeWatchNumOfCategories"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="d-flex">
      <v-spacer />
      <v-btn color="success" type="submit">{{ submitBtnName }}</v-btn>
    </div>
  </v-form>
</template>

<script>
import AddCompetitorModal from '@/components/Modals/AddСompetitorModal'
import generateID from '@/utils/idGenerator'
import EditCompetitorModal from '@/components/Modals/EditCompetitorModal'

export default {
  name: 'projectConfigureForm',
  components: {
    EditCompetitorModal,
    AddCompetitorModal,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      valid: true,
      searchCompetitor: '',
      headers: [
        {text: 'Nazwa konkurencji', value: 'name'},
        {text: 'Domena', value: 'domain'},
        {text: 'Ilość kategorii do podbicia', value: 'numberOfSuggestedProducts'},
        {text: '', value: 'actions', width: '5%', sortable: false},
      ],
    }
  },
  methods: {
    validateField(refName) {
      this.$refs[refName].validate()
    },
    addCompetitor(val) {
      this.project.concurrents.push({
        ...val,
        competitorID: generateID(),
      })
    },
    editCompetitor(competitor) {
      const {concurrents} = this.project

      const newConcurrents = [...concurrents]
      const index = newConcurrents.findIndex(item => item.competitorID === competitor.competitorID)
      newConcurrents[index] = competitor

      this.project.concurrents = newConcurrents
    },
    async saveProject() {
      if (this.$refs.form.validate()) {
        this.$emit('formSubmitted')
      }
    },
  },
  computed: {
    submitBtnName() {
      return this.editing ? 'Zapisz zmiany' : 'Stwórz projekt'
    },
    senutoMinRule() {
      return () =>
        this.project.senutoMinimumSearch <= this.project.senutoMaximumSearch ||
        'Minimalna ilość wyszukiwań nie może być większa od maksymalnej ilości wyszukiwań.'
    },
    senutoMaxRule() {
      return () =>
        this.project.senutoMaximumSearch >= this.project.senutoMinimumSearch ||
        'Maksymalna ilość wyszukiwań nie może być mniejsza od minimalnej ilości wyszukiwań.'
    },
  },
}
</script>

<style scoped></style>

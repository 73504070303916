<template>
  <DefaultModal
    ref="modal"
    @formSubmitted="onFormSubmitted"
    activator-name="Dodaj"
    title="Dodawanie konkurencji"
  >
    <v-text-field
      :rules="[...$rules.required, ...$rules.maxLength(50)]"
      v-model="competitor.name"
      outlined
      dense
      label="Nazwa konkurencji"
    />
    <v-text-field
      :rules="[...$rules.required, ...$rules.maxLength(50)]"
      v-model="competitor.domain"
      :hint="`Nazwa domeny bez 'https://www.' Np. 'example.com'`"
      outlined
      dense
      label="Domena"
    />
    <v-text-field
      :rules="[...$rules.required, ...$rules.intOnly]"
      v-model="competitor.numberOfSuggestedProducts"
      outlined
      dense
      label="Ilość kategorii do podbicia"
    />
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/Modals/DefaultModal'

export default {
  name: 'AddСompetitorModal',
  components: {DefaultModal},
  data() {
    return {
      defaultCompetitor: {
        name: '',
        domain: '',
        numberOfSuggestedProducts: 0,
      },
      competitor: {
        name: '',
        domain: '',
        numberOfSuggestedProducts: 0,
      },
    }
  },
  methods: {
    onFormSubmitted() {
      this.$emit('addedCompetitor', this.competitor)
      this.competitor = {
        ...this.defaultCompetitor,
      }
      this.$refs.modal.close()
    },
  },
}
</script>

<style scoped></style>

export default function createProjectModel() {
  return {
    name: '',
    domain: '',
    ceneoAPIKey: '',

    procentOfUnbiddedProductsInCategoryToUnbid: 0,
    bidding: false,

    senutoRaport: false,
    senutoMinimumSearch: 0,
    senutoMaximumSearch: 0,
    senutoComparingKeywordsPercentage: 0,
    concurrents: [],

    searchNodeRaport: false,
    searchNodeComparingKeywordsPercentage: 0,
    searchNodeNumberOfCategoriesToBid: 0,

    exactagRaport: false,
    exactagNumberWeeklyBidding: 0,
    exactagNumberWeeklyBiddingYearAgo: 0,

    tradeWatchRaport: false,
    tradeWatchNumOfCategories: 0,

    maxAmountOfProductsInCategoryForBlacklist: 0,
  }
}

<template>
  <v-dialog :max-width="370" v-model="show">
    <template #activator="{on}">
      <v-btn icon v-on="on">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <template #default>
      <v-form ref="form" v-model="valid" @submit.prevent="save">
        <v-card>
          <v-card-title class="py-6">
            <h6 class="text-h6 mx-auto">Edycja konkurencji</h6>
          </v-card-title>
          <v-card-text>
            <v-text-field
              :rules="[...$rules.required, ...$rules.maxLength(50)]"
              v-model="item.name"
              outlined
              dense
              label="Nazwa konkurencji"
            />
            <v-text-field
              :rules="[...$rules.required, ...$rules.maxLength(50)]"
              v-model="item.domain"
              :hint="`Nazwa domeny bez 'https://www.' Np. 'example.com'`"
              outlined
              dense
              label="Domena"
            />
            <v-text-field
              :rules="[...$rules.required, ...$rules.intOnly]"
              v-model="item.numberOfSuggestedProducts"
              outlined
              dense
              label="Ilość kategorii do podbicia"
            />
          </v-card-text>
          <v-divider />
          <v-card-actions class="py-5">
            <v-spacer />
            <v-btn elevation="0" @click="cancel">Anuluj</v-btn>
            <v-btn color="primary" type="submit">Zapisz</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: 'EditCompetitorModal',
  props: {
    competitor: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.item = {
      ...this.competitor,
    }
  },
  data() {
    return {
      valid: true,
      show: false,
      item: {},
    }
  },
  methods: {
    cancel() {
      this.item = {...this.competitor}
      this.close()
    },
    close() {
      this.show = false
    },
    save() {
      if (!this.$refs.form.validate()) return
      this.$emit('update:competitor', {...this.item})
      this.close()
    },
  },
}
</script>

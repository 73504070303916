<template>
  <ProjectConfigureForm @formSubmitted="saveProject" :project="project" />
</template>

<script>
import ProjectConfigureForm from '@/components/Projects/ProjectConfigureForm'
import {mapActions} from 'vuex'
import createProjectModel from '@/utils/projects/createProjectModel'

export default {
  name: 'AddProject',
  components: {ProjectConfigureForm},
  data() {
    return {
      project: createProjectModel(),
      loading: false,
    }
  },
  methods: {
    ...mapActions(['createProject', 'showNotification']),
    async saveProject() {
      try {
        this.loading = true
        await this.createProject(this.project)
        this.$router.push({name: 'Projects'})
        this.loading = false
        this.showNotification({
          type: 'success',
          message: 'NOTY_PROJECT_CREATED',
        })
      } catch (e) {
        this.showNotification({
          type: 'error',
          message: e.response.data,
        })
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
